// Import webfont
@font-face {
  font-family: 'ddb';
  src: url('ddb.eot');
  src: url('ddb.eot?#iefix') format('embedded-opentype'),
    url('ddb.woff2') format('woff2'),
    url('ddb.woff') format('woff'),
    url('ddb.ttf') format('truetype'),
    url('ddb.svg#ddb') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gtbl';
  src: url('gtbl.eot');
  src: url('gtbl.eot?#iefix') format('embedded-opentype'),
    url('gtbl.woff2') format('woff2'),
    url('gtbl.woff') format('woff'),
    url('gtbl.ttf') format('truetype'),
    url('gtbl.svg#gtbl') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gtmd';
  src: url('gtmd.eot');
  src: url('gtmd.eot?#iefix') format('embedded-opentype'),
    url('gtmd.woff2') format('woff2'),
    url('gtmd.woff') format('woff'),
    url('gtmd.ttf') format('truetype'),
    url('gtmd.svg#gtmd') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gtbk';
  src: url('gtbk.eot');
  src: url('gtbk.eot?#iefix') format('embedded-opentype'),
    url('gtbk.woff2') format('woff2'),
    url('gtbk.woff') format('woff'),
    url('gtbk.ttf') format('truetype'),
    url('gtbk.svg#gtbk') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Typography Defaults
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-ddb: "ddb","Hoefler Text",Garamond,"Times New Roman",serif;
$font-family-gtmd: 'gtmd', var(--font-family-sans-serif);
$font-family-gtbl: 'gtbl', var(--font-family-sans-serif);
$font-family-gtbk: 'gtbk',var(--font-family-sans-serif);



// $font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

// $font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-base: 1rem;
// $font-size-lg:                $font-size-base * 1.25 !default;
// $font-size-sm:                $font-size-base * .875 !default;

// $font-weight-lighter:         lighter !default;
// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;
// $font-weight-bold:            700 !default;
// $font-weight-bolder:          bolder !default;

// $font-weight-base:            $font-weight-normal !default;
// $line-height-base:            1.5 !default;

// $h1-font-size:                $font-size-base * 2.5 !default;
$h1-font-size: $font-size-base * 4;
// $h2-font-size:                $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 3;
// $h3-font-size:                $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 2;
// $h4-font-size:                $font-size-base * 1.5 !default;
// $h5-font-size:                $font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;

// $headings-margin-bottom:      $spacer / 2 !default;
// $headings-font-family:        null !default;
// $headings-font-weight:        500 !default;
// $headings-line-height:        1.2 !default;
// $headings-color:              null !default;

// $display1-size:               6rem !default;
// $display2-size:               5.5rem !default;
// $display3-size:               4.5rem !default;
// $display4-size:               3.5rem !default;

// $display1-weight:             300 !default;
// $display2-weight:             300 !default;
// $display3-weight:             300 !default;
// $display4-weight:             300 !default;
// $display-line-height:         $headings-line-height !default;

// $lead-font-size:              $font-size-base * 1.25 !default;
// $lead-font-weight:            300 !default;

// $small-font-size:             80% !default;

// $text-muted:                  $gray-600 !default;

// $blockquote-small-color:      $gray-600 !default;
// $blockquote-small-font-size:  $small-font-size !default;
// $blockquote-font-size:        $font-size-base * 1.25 !default;

// $hr-border-color:             rgba($black, .1) !default;
// $hr-border-width:             $border-width !default;

// $mark-padding:                .2em !default;

// $dt-font-weight:              $font-weight-bold !default;

// $kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
// $nested-kbd-font-weight:      $font-weight-bold !default;

// $list-inline-padding:         .5rem !default;

// $mark-bg:                     #fcf8e3 !default;

// $hr-margin-y:                 $spacer !default;

h1, h2 {
  font-family: $font-family-gtmd;
  text-transform: uppercase;
}
h3,h4,h5,h6 {
  font-family: $font-family-ddb;
  text-transform: uppercase;
}
