// Override some bootstrap vars
// see ../bootstrap/_variables.scss for all defaults

// $primary:       $blue;
// $secondary:     $gray-600;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$primary: #12884E;
$secondary: #12884E;
$light-gray: #939598;
$dark-gray: #6D6E71;
$light-blue: #E6E7E8;

// Custom vars used in sass files
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// Defaults
// $enable-caret: true;
// $enable-rounded: true;
// $enable-shadows: false;
// $enable-gradients: false;
// $enable-transitions: true;
// $enable-prefers-reduced-motion-media-query: true;
// $enable-grid-classes: true;
// $enable-pointer-cursor-for-buttons: true;
// $enable-print-styles: true;
$enable-responsive-font-sizes: true;
// $enable-validation-icons: true;
// $enable-deprecation-messages: true;

.center-row {
  justify-content: center;
}
