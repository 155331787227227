#main-nav {
  .nav-link {
    color: $white;
    text-transform: uppercase;
    padding: 0 30px;
    font-weight: bold;
  }
}

ul.social-list {
  margin-bottom: 0px;
  li {
    a {
      color: $light-gray;
    }
  }
}

.logo-branding-link {
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.home-logo {
  width: 300px;
  margin: .25em auto .5em;
}

.top-left-cta {
  color: $light-gray;
}

.swiper-slide {
  .page-title-container {
    .title-container {
      padding: .6rem 5rem .6rem 3rem;
      max-width: 763px;
      .swiper-image-link:hover {
        text-decoration: none;
      }
      h1 {
        text-transform: uppercase;
        margin: 0;
        text-align: left;
      }
      .line1 {
        font-family: $font-family-ddb;
        font-size: 5rem;
      }

      .line2 {
        font-family: $font-family-gtmd;
        font-size: 1.35rem;
      }
      background: transparent url(/assets/images/ssbg.png) 0 0 repeat-y;
      filter: hue-rotate(280deg);
      -webkit-filter: hue-rotate(280deg);
      background-size: contain;
    }
  }
}

#featured-pages {
  .featured-page {
    .image-container {
      overflow: hidden;
      // img {
      //   transform: scale(2,2) translateY(20%);
      // }
      h3 {
        z-index: 100;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        text-transform: uppercase;
        max-width: 40%;
        text-align: left;
        font-weight: bold;
        font-family: $font-family-gtbl;
        font-size: 1.5rem;
      }
    }
  }
  .col-sm-6:nth-child(even) {
    .featured-page {
      .image-container {
        h3 {
          left: auto;
          right: 1rem;
          text-align: right;
        }
      }
    }

  }
}

#main {
  .content {
    text-align: center;
    h3 {
      color: $secondary;
      text-transform: uppercase
    }
  }
}

h3#cunningham-insurance-group-consists-of-three-agencies- {
  font-size: 1.25rem;
}

.front {
  .recent-news {
    background: $light-blue;
    .read-more {
      font-weight: bold;
    }
  }
}

#footer-nav {
  .nav-link {
    line-height: 1rem;
  }
}

.footer-left {
  padding-top: 1.5rem;
}

h3.footer-contact {
  font-size: 1rem;
  font-family: $font-family-gtmd;
  margin-bottom: 0;
}

#subpage-blocks {
  .card {
    background: $light-blue;
    .card-img-top {
      width: 75px;
      height: auto;
      max-width: 100%;
      filter: hue-rotate(280deg) brightness(1.5);
      -webkit-filter: hue-rotate(280deg) brightness(1.5);
      mix-blend-mode: multiply;
    }
    .card-title-link {
      color: $secondary;
      font-family: $font-family-gtbl;
      font-size: 20px;
    }
  }
}
