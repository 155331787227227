#featured-pages {
  .featured-page {
    a.image {
      img {
        max-height: 180px;
      }
    }
  }
}
#blog-posts {
  .card {
    .card-body {
      .card-title {
        line-height: 36px;
      }
    }
  }
}
