.date {
  float: left;
  height: 36px;
  width: 36px;
  margin-right: 10px;
  padding-top: 0px;
  line-height: normal;

  .month {
    display: block;
    text-align: center;
    color: #FFF;
    font-size: 10px;
    padding-top: 2px;
    text-transform: uppercase;
  }

  .day {
    display: block;
    text-align: center;
    padding-top: 0px;
    color: #222;
    font-size: 14px;
    font-weight: bold;
  }

}

.meta {
  display: block;
  font-size: 11px;
  color: #666;
}
